:root {
  --bs-body-color: #ffffff;
  --bs-body-bg: #000000;
  --bs-dark-rgb: 16, 8, 70;
  --bs-light-rgb: 199, 201, 219;
  --bs-neon: #ffeb3b;
}

body {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
}

h1,
h2 {
  font-family: 'Archivo Black', Arial, Helvetica, sans-serif;
}

header {
  z-index: 1;
  margin-top: 70px;
  top: 0;
}

section {
  opacity: 0;
  background-size: cover;
  background-position-y: bottom;
}

.team-description {
  width: 175px;
}

.navbar-brand img {
  width: 70px;
  margin-left: 8px;
}

.navbar-toggler {
  box-shadow: none!important;
  border: none!important;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgb%28255, 255, 255%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  filter: brightness(0.55)
}

.navbar-dark .navbar-toggler-icon:hover {
  filter: brightness(1)
}

#team {
  background-image: url(https://res.cloudinary.com/s3ansh33p/image/upload/v1633533824/team_zqjkgq.webp);
}

#backstory, #upgrades, #roadmap {
  background-image: url(https://res.cloudinary.com/s3ansh33p/image/upload/v1633533823/background_dhs8yh.webp);
}

#discord {
  background-image: url(https://res.cloudinary.com/s3ansh33p/image/upload/v1633533824/discord_vli3xl.webp);
}

.bg-head {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position-x: center;
  background-image: url(https://res.cloudinary.com/s3ansh33p/image/upload/v1633533824/header_lammu4.webp);
}

#footer-bg {
  background-image: url(https://res.cloudinary.com/s3ansh33p/image/upload/v1633533824/footer_l7xzp9.webp);
  background-position-y: bottom;
}

#loading {
  margin-left: -4px;
}

.team-image {
  max-width: 100px;
  border-radius: 10px;
  transition: 0.4s;
  transition-property: transform;
}

.team-image:hover {
  transform: scale(1.1);
}

a:hover {
  cursor: pointer;
}

.hero-image {
  height: clamp(150px, 25vw, 500px);
  width: clamp(150px, 25vw, 500px);
}

.accordion-button::after {
  filter: brightness(9);
}

.product-image {
  max-width: 450px;
}

.car-item {
  text-align: -webkit-center;
}

.car-width {
  max-width: 400px;
}

footer {
  margin-left: -10px;
}

.fab {
  font-size: 20px;
  width: 20px;
  text-align: center;
  text-decoration: none;
  margin: 5px;
  border-radius: 50%;
  color: white;
}

.fab:hover {
  color: #ffd400;
}

.hover-white:hover {
  color: white;
}

.fa-solsea {
  -webkit-transform: scale(.64);
  transform: scale(.64);
}

.navbar-dark {
  background: linear-gradient(rgba(0,0,0,1), rgba(0,0,0,0.75));
}

::-webkit-scrollbar {
  width: 0px;
}

.roadmap-img {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  width: 100px;
  background-position-x:left;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(https://res.cloudinary.com/s3ansh33p/image/upload/v1634033873/phase-background-panel_fzcnit.jpg);
}
.roadmap-right {
  width: calc(100% - 100px);
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  background: #19114c;
  z-index: 2;
}
.roadmap-timeline {
  background: #19114c;
  width: 10px;
  height: 100%;
}
.roadmap-timedots {
  background: #36269b;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  z-index: 2;
}
.text-achieved {
  color: #ffeb3b;
}
.roadmap-timedot-container {
  margin-left: -5px;
}
.roadmap-timeactive {
  margin-top: 25px;
  margin-left: -20px;
}
.roadmap-timeactive > div {
  background: #ffeb3b;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  z-index: 1;
}
.roadmap-timeline-fill {
  background: #ffeb3b;
  width: 10px;
}
.roadmap-timeline-margin {
  margin-right: 3rem;
}
.roadmap-row {
  background: #ffeb3b;
  width: 0;
  height: 10px;
}
.roadmap-right > * > * > * {
  background: #271c71;
}
.roadmap-card-container {
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
.roadmap-font {
  color: #ffeb3b;
  height: 25%;
}
.roadmap-large {
  -webkit-text-fill-color: rgba(0,0,0,0);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #ffeb3b;
  font-size: calc(6rem + 1.5vw);
  font-weight: 900;
}
.roadmap-card {
  opacity: 1;
}
.cursor-pointer:hover {
  cursor: pointer;
}
@media screen and (max-width: 769px) {
  .roadmap-img {
      display: none;
  }
  .roadmap-right {
      width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .roadmap-timeline {
      display: none;
  }
  .roadmap-timeline-margin {
      margin: 0;
  }
}

ul.timeline {
  list-style-type: none;
  position: relative;
  padding-left: 1.5rem;
  font-weight: 600;
}

ul.timeline:before {
  content: ' ';
  background: #fff;
  display: inline-block;
  position: absolute;
  left: 16px;
  width: 4px;
  height: 100%;
  z-index: 400;
  border-radius: 1rem;
}

li.timeline-item {
  margin: 20px 0;
}

.timeline-arrow {
  border-top: 0.5rem solid transparent;
  border-right: 0.5rem solid rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
  border-bottom: 0.5rem solid transparent;
  display: block;
  position: absolute;
  left: 2rem;
}

li.timeline-item::before {
  content: ' ';
  background: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #fff;
  left: 11px;
  width: 14px;
  height: 14px;
  z-index: 400;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.btn-neon {
  font-size: 20px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: var(--bs-neon);
  border: var(--bs-neon) 3px solid;
  background-color: transparent;
  border-radius: 0.25em;
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em currentColor;
  box-shadow: inset 0 0 0.5em 0 var(--bs-neon), 0 0 0.5em 0 var(--bs-neon);
  transition: all 0.15s
}

.btn-neon:hover {
  background-color: var(--bs-neon);
  color: deeppink;
  text-shadow: none;
}

.btn-neon-small {
  padding: 5px;
  height: 35px;
  font-size: 15px;
  width: 50px;
}

#particle-div-1, #particle-div-2 {
  width: 40%;
  position: absolute;
  height: 100%;
  -webkit-mask-image: linear-gradient(to left, rgba(0,0,0,1), rgba(0,0,0,0));
  -webkit-mask-repeat: no-repeat;
}

#particle-div-2 {
  right: 0;
  -webkit-mask-image: linear-gradient(to right, rgba(0,0,0,0.2), rgba(0,0,0));
}

.particles-container {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-mask-position-x: center;
  -webkit-mask-size: cover;
  -webkit-mask-image: url(https://res.cloudinary.com/s3ansh33p/image/upload/v1635069884/mask_gofjrg.svg)
}

video {
  left: 0;
}

.volume {
  right: 0;
  bottom: 0;
  height: 21.33px;
  width: 24px;
  margin-bottom: 5px;
  margin-right: 8px;
  color: white;
}